import React, {useEffect} from 'react';
import './App.css';
import html2canvas from "html2canvas";
import {jsPDF} from "jspdf";


function App(): JSX.Element {

    let [name, setName] = React.useState("");
    let [adress, setAdress] = React.useState("");
    let [legend, setLegend] = React.useState("");
    let [dc, setDc] = React.useState("");
    let [dlc, setDlc] = React.useState("");


    useEffect(() => {
        document.documentElement.classList.add('h-full', 'bg-gray-100');
        document.body.classList.add('h-full');

    })

    useEffect(() => {load();}, [])

    function save(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        localStorage.setItem("name", name);
        localStorage.setItem("adress", adress);
        localStorage.setItem("legend", legend);
        localStorage.setItem("dc", dc);
        localStorage.setItem("dlc", dlc);
    }

    function load() {
        setName(localStorage.getItem("name") || "Waroquier Philippe");
        setAdress(localStorage.getItem("adress") || "Rue de l'enfer, 9\n" +
            "7120 Estinnes-au-Val\n" +
            "Belgique\n" +
            "+32 (0)64 33 70 29");
        setLegend(localStorage.getItem("legend") || "Toutes fleurs");
        setDc(localStorage.getItem("dc") || "Printemps / Eté YYYY");
        setDlc(localStorage.getItem("dlc") || "Juin YYYY");
    }

    function print() {
        const printArea = document.getElementById("printWrapper") as HTMLElement;
        if (printArea) {
            html2canvas(printArea).then(canvas => {
                const dataURL = canvas.toDataURL('img/png');
                const pdf = new jsPDF('p', 'mm', 'a4');

                for (let y = 0; y < 70 * 4; y += 70) {
                    for (let x = 0; x < 2; x++) {
                        pdf.addImage(dataURL, 'JPEG', 0, y, 105, 70);
                        pdf.addImage(dataURL, 'JPEG', 105, y, 105, 70);
                    }
                }
                pdf.save('saved.pdf')
            })
        }
    }

    return (
        <div className="min-h-full flex gap-2 ">
            <div className="sidebar h-screen w-96 p-5 relative">
                <form action="" onSubmit={save}>
                    <div className="grid grid-cols-1 gap-6">
                        <label className="block">
                            <span className="text-gray-700">Nom</span>
                            <input type="text"
                                   value={name}
                                   onChange={(e) => setName(e.target.value)}
                                   className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                   placeholder=""/>
                        </label>

                        <label className="block">
                            <span className="text-gray-700">Adresse & infos</span>
                            <textarea
                                className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                                value={adress}
                                onChange={(e) => setAdress(e.target.value)}
                                rows={6}>
                        </textarea>
                        </label>

                        <label className="block">
                            <span className="text-gray-700">Légende</span>
                            <input type="text"
                                   value={legend}
                                   onChange={(e) => setLegend(e.target.value)}
                                   className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                   placeholder=""/>
                        </label>
                        <label className="block">
                            <span className="text-gray-700">Date de récolte</span>
                            <input type="text"
                                   value={dc}
                                   onChange={(e) => setDc(e.target.value)}
                                   className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                   placeholder=""/>
                        </label>
                        <label className="block">
                            <span className="text-gray-700">Date limite de consommation</span>
                            <input type="text"
                                   value={dlc}
                                   onChange={(e) => setDlc(e.target.value)}
                                   className=" mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 "
                                   placeholder=""/>
                        </label>

                        <div className="py-3 bg-gray-50 flex justify-between items-stretch">
                            <button type="button"
                                    onClick={print}
                                    className="flex bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Exporter en PDF
                            </button>
                            <button type="submit"

                                    className="flex inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                Sauvegarder
                            </button>
                        </div>
                    </div>

                </form>
                <div className="absolute flex flex-row flex-nowrap items-center justify-center bottom-10 w-96 h-[50px]">
                    <div className="flex"><img className=" mx-1 w-[15px]" src={"nut.png"} alt=""/></div>
                    <div className="flex mx-1">Powered by</div>
                    <div className="flex "><a className="text-[#F16323] hover:underline" href="https://www.nixinut.com">Nixinut</a></div>
                </div>
            </div>

            <div className="flex flex-grow justify-center items-center bg-[#EBECF0]">
                <div id="printWrapper" className=" w-[900px] h-[600px]">
                    <div className="bg-[#FFE08C] w-full aspect-[10/6.65] overflow-hidden relative px-14 py-8">
                        <div className=" w-full h-full z-20 relative">
                            <div
                                className="flex items-center content-center justify-center w-full h-full  z-20 flex-col">
                                <img src={"bee.png"} alt="bee" className="flex w-[35%] z-20"/>
                                <span
                                    className="text-center text-3xl text-black font-bold uppercase z-20 mb-4 mt-2 font-['Poppins'] font-medium tracking-widest">{legend}</span>
                            </div>

                            <div className="dr dlc absolute top-0 right-0">
                                <h2 className="text-right text-2xl text-black font-bold  font-['Poppins'] tracking-widest">Apiculteur</h2>
                                <div
                                    className="text-right mt-4 text-2xl text-black font-light  font-['Poppins'] tracking-wide whitespace-pre-line">{name}</div>
                                <div
                                    className="text-right mt-4 text-xl text-[#272030] font-light leading-5  font-['Poppins'] tracking-wide whitespace-pre-line">{adress}</div>
                            </div>

                            <div className="dr dlc absolute bottom-0 left-0">
                                <h2 className="text-xl text-black font-bold uppercase font-['Poppins'] tracking-widest">Date
                                    de récolte</h2>
                                <div
                                    className="mt-2 text-xl text-[#272030] font-light  font-['Poppins'] tracking-wide">{dc}</div>

                                <h2 className="mt-8 text-xl text-black font-bold uppercase font-['Poppins'] tracking-widest">Date
                                    limite de consommation</h2>
                                <div
                                    className="mt-2 text-xl text-[#272030] font-light font-['Poppins'] tracking-wide">{dlc}</div>
                            </div>

                            <div className="conservation absolute bottom-0 right-0">
                                <div
                                    className="text-right text-xl text-[#272030] font-light font-['Poppins'] tracking-wide">
                                    A conserver dans <br/>
                                    un endroit frais et sec
                                </div>
                            </div>
                        </div>

                        <div
                            className="bg-[#FBD160] w-[200%] h-3/5 rotate-[30deg] mt-[48%] absolute top-[-29%] left-[-25%] z-10"></div>

                    </div>

                </div>
            </div>
        </div>
    );
}

export default App;
